/*------------- #VARIABLES --------------*/



// We use these as default colors throughout
$primary-color: #ff5e3a !default;
$secondary-color: #3f4257;
$placeholder-form-color: #888da8;
$white-color: #fff !default;
$heading-color: #515365;
$icon-color: #9a9fbf;



// We use these to control various global styles
$body-bg: #edf2f6 !default;
$body-font-color: #888da8 !default;
$body-bg-white: #ffffff !default;


// We use these as default border style
$border-color: #e6ecf5;

$grey: #515365;
$grey-light: #888da8;
$grey-lighter: #9a9fbf;
$purple: #7c5ac2;
$purple-dark: #6236b2;
$blue: #38a9ff;
$breez: #08ddc1;
$breez-light: #2aebcb;
$orange: #ff763a;
$yellow: #ffdc1b;
$smoke: #e6ecf5;
$green: #1ed760;
$smoke-light: #fafbfd;
$blue-light: #00b7ff;
$violet: #7442ce;

$c-facebook: #2f5b9d;
$c-twitter: #38bff1;
$c-dribbble: #f74881;
$c-rss: #fab819;
$c-spotify: #1ed760;
$c-google: #ea4235;


$headings-font-weight-h1-h4:   300;