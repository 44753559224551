/*------------- #REGISTRATION-LOGIN-FORM --------------*/


.registration-login-form {
  border: 1px solid #e6ecf5;
  border: 1px solid var(--olympus-border-color);
  border-radius: 0 5px 5px 0;
  background-color: #fff;
  background-color: var(--olympus-white-color);
  overflow: hidden;
  position: relative;
  padding-left: 71px;
  margin-bottom: 20px;
  min-height: 700px;

  .nav-tabs {
	float: left;
	display: block;
	height: 100%;
	border: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
  }

  .nav-item {
	margin-bottom: 0;
	height: 50%;
	display: table;

	&:last-child .nav-link {
	  border-bottom: 0;
	}
  }

  .nav-link {
	border-radius: 0;
	border: none;
	border-bottom: 1px solid #e6ecf5;
	border-bottom: 1px solid var(--olympus-border-color);
	border-right: 1px solid #e6ecf5;
	border-right: 1px solid var(--olympus-border-color);
	padding: 25px;
	color: #c0c4d8;
	fill: #c0c4d8;
	background-color: #fafbfd;
	background-color: var(--olympus-smoke-light);
	height: 100%;
	display: table-cell;
	vertical-align: middle;

	&.active {
	  fill: #ff5e3a;
	  fill: var(--olympus-primary-color);
	  background-color: #fff;
	  background-color: var(--olympus-white-color);
	  border-color: #e6ecf5;
	  border-color: var(--olympus-border-color);
	}

	svg {
	  width: 21px;
	  height: 21px	;
	}
  }

  .tab-content {
	overflow: hidden;
  }

  .content {
	padding: 35px 25px;
  }

  .title {
	padding: 25px;
	border-bottom: 1px solid #e6ecf5;
	border-bottom: 1px solid var(--olympus-border-color);
	margin-bottom: 0;
  }

  p {
	margin-bottom: 0;
	font-size: 13px;
  }

  .remember {
	margin: 35px 0 25px
  }
}

.remember {
  margin-bottom: 1rem;

  .checkbox {
	display: inline-block;
	margin-bottom: 0;
  }

  a {
	line-height: 2;
  }

  .forgot {
	float: right;
	color: #888da8;
	color: var(--olympus-body-font-color);
  }
}

.or {
  position: relative;
  width: 100%;
  height: 1px;
  margin: 1rem 0 2rem 0;
  background-color: #e6ecf5;
  background-color: var(--olympus-border-color);

  &:after {
	content: 'OR';
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	background-color: var(--olympus-white-color);
	padding: 0 25px;
	font-size: 10px;
	z-index: 5;
  }
}

.form--login-logout {
  width: 100%;
  margin-right: 30px;

  .form-group {
	margin-bottom: 0;
  }

  form {
	justify-content: flex-end;
  }

  .form-control {
	background-color: #494c62;
	border: 0;
	color: #9a9fbf;
	color: var(--olympus-grey-lighter);
  }

  .login-btn-responsive {
	display: none;
  }

  button.btn {
	padding: .578rem 2.1rem;
  }
}


/*================= Responsive Mode ============*/


@media (max-width: 1023px) {
  .registration-login-form {
	padding-top: 71px;
	padding-left: 0;
  }

  .registration-login-form .nav-tabs {
	bottom: auto;
	height: auto;
	width: 100%;
  }

  .registration-login-form .nav-item {
	height: 100%;
	display: block;
	width: 50%;
	float: left;
	text-align: center;
  }

  .registration-login-form .nav-link {
	display: block;
  }

  .registration-login-form .nav-item:last-child .nav-link {
	border-bottom: 1px solid #e6ecf5;
	border-bottom: 1px solid var(--olympus-border-color);
  }
}

@media (max-width: 580px) {
  .form--login-logout {
	text-align: right;

	form {
	  display: none;
	}

	.btn {
	  display: none;
	  margin-bottom: 0;

	  &.login-btn-responsive {
		display: inline-block;
		padding: .6rem 1.5rem;
	  }
	}
  }
}
