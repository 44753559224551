/*------------- #W-SEARCH--------------*/



.w-search {
  width: 340px;

  .form-group {
	margin-bottom: 0;
  }

  input {
	padding: 13px 15px;
	font-size: 12px;
  }

  svg {
	height: 15px;
	width: 15px;
  }
}