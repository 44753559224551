/*------------- #PAGINATION --------------*/


.pagination {
  margin-top: 40px;
  margin-bottom: 40px;
  flex-wrap: wrap;

  .page-item {
	font-size: 12px;
	font-weight: 700;
	padding: 0 4px;
  }

  .page-link {
	border-radius: 5px;
  }
}

.pagination--small {
  margin-top: 15px;
  margin-bottom: 15px;

  .page-link {
	padding: 0.25rem .45rem;
	font-size: 8px;
  }
}