/*------------- #W-CONTACTS --------------*/


.w-contacts {

  #map {
	width: 100%;
	height: 200px;
	margin-bottom: 15px;
  }

  ul {
	padding: 0 25px;

	li {
	  padding: 15px 0;
	}

	.title {
	  margin-right: 0;
	  float: none;
	  width: auto;
	  color: #515365;
	  color: var(--olympus-heading-color);
	  font-weight: 500;
	}

	.text {
	  color: #888da8;
	  color: var(--olympus-body-font-color);
	  display: block;
	  overflow: hidden;
	}
  }
}

#map {
  width: 100%;
  max-height: 90vh;
  z-index: 10;
}