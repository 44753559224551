/*------------- #SECTION --------------*/



.bg-section1 {
  background-image: url("../img/bg-section1.webp");
}

.bg-section2 {
  background-image: url("../img/bg-section2.webp");
}

.bg-section3 {
  background-image: url("../img/bg-section3.webp");
}

.bg-section4 {
  background-image: url("../img/bg-section4.webp");
}

.bg-section5 {
  background-image: url("../img/bg-section5.webp");
}

section {
  position: relative;
}

.section-move-bg {

  .content-bg-wrap {
	background-position: bottom;
	background-repeat: repeat-x;
	background-size: 100% 100%;
  }

  .container {
	z-index: 5;
	position: relative;
  }
}

.section-move-bg-top {
  .content-bg-wrap {
	background-position: top;
	background-repeat: repeat-x;
	bottom: auto;
	top: 0;
  }

  .container {
	z-index: 5;
	position: relative;
  }
}

.call-to-action-animation {
  .first-img {
	position: absolute;
	left: 15%;
	opacity: 0;
	transition: all .3s ease;
	bottom: 0;
	transform: scale(0);
	z-index: 5;
  }

  .second-img {
	position: absolute;
	right: 40%;
	opacity: 0;
	transition: all .3s ease;
	top: 0;
	z-index: 5;
  }
}

.img-scale-animation {
  img {
	position: absolute;
	box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
	transform: scale(0);
	opacity: 0;
	display: block;

	&.main-img {
	  position: relative;
	  box-shadow: none;
	}

	&.first-img1 {
	  left: 2%;
	  top: 5%;
	}

	&.second-img1 {
	  left: 12%;
	  top: 50%;
	}

	&.third-img1 {
	  right: 2%;
	  top: 8%;
	}
  }
}

.subscribe-animation {
  position: relative;

  .plane {
	opacity: 0;
	position: absolute;
	top: 0;
	right: 5%;
  }
}

.planer-animation {
  position: relative;
  background-position: bottom;

  .planer {
	position: absolute;
	top: 10%;
	right: 0;
	opacity: 0;
  }
}

.contact-form-animation {
  position: relative;

  .crew {
	opacity: 0;
	position: absolute;
	bottom: 20%;
	transform: scale(0);
	right: 0;
  }
}

.block-action {
  padding: 60px 70px 40px;
  border-radius: 5px;
  min-height: 260px;
  display: flex;

  & > * {
	margin-top: auto;
	min-width: 100%;
  }
}

/* Text meant only for screen readers. */

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

.call-to-action-animation,
.planer-animation {
  overflow: hidden;
}

/*------------- #Styles for preloader --------------*/


#hellopreloader {
  display: none;
  position: fixed;
  text-align: center;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  background: lighten($primary-color, 10%);
  background-size: 41px;
  opacity: 0;

  &.active {
	display: block;
	opacity: 1;
  }
}

.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .text {
	width: 100%;
	color: #fff;
	color: var(--olympus-white-color);
	font-size: 14px;
	margin-top: 10px;
  }
}


/*================= Responsive Mode ============*/

@media (max-width: 1024px) {
  .call-to-action-animation .first-img,
  .call-to-action-animation .second-img {
	max-width: 50%;
	max-height: 50%;
  }
}

@media (max-width: 860px) {
  .img-scale-animation img.first-img1 {
	top: 0;
	max-height: 100%;
  }

  .img-scale-animation img.second-img1 {
	top: 0;
	max-height: 100%;
  }

  .img-scale-animation img.third-img1 {
	top: 0;
	max-height: 100%;
  }

  .subscribe-animation .plane {
	max-width: 200px;
	max-height: 100%;
  }
}

@media (max-width: 768px) {
  .planer-animation .planer {
	max-width: 10%;
  }
}

@media (max-width: 460px) {
  .block-action {
	padding: 60px 50px 10px;
  }

  .subscribe-animation .plane {
	max-width: 100px;
  }
}
